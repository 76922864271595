<script>
export default {
  name: "AppLogo"
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="166" height="46" viewBox="0 0 166 46" fill="none">
    <g clip-path="url(#clip0_577_6679)">
      <path d="M7.73263 15.3132L20.6008 7.85956C20.6763 7.82061 20.7602 7.80025 20.8454 7.80025C20.9305 7.80025 21.0144 7.82061 21.0899 7.85956L34.0266 15.3132C34.0763 15.3432 34.1195 15.3826 34.1537 15.4292L41.0427 11.5006C40.6357 10.7676 40.0466 10.149 39.3308 9.70304L23.3275 0.572614C21.9874 -0.190871 19.7938 -0.190871 18.4537 0.572614L2.45037 9.70304C1.73392 10.1482 1.14463 10.767 0.738525 11.5006L7.62503 15.4292C7.65363 15.3845 7.69007 15.3452 7.73263 15.3132Z" fill="#5EC7EB"/>
      <path d="M20.5984 38.1423L7.73265 30.8264C7.66152 30.7806 7.60231 30.7188 7.55982 30.6462C7.51734 30.5736 7.49277 30.4921 7.4881 30.4083V15.7306C7.49381 15.6206 7.53398 15.515 7.60304 15.4286L0.716529 11.5C0.277141 12.2168 0.030492 13.0327 0 13.8702V32.1286C0 33.658 1.09558 35.5329 2.44549 36.2988L18.4488 45.4268C19.2013 45.8203 20.0431 46.0174 20.8943 45.9994V38.1978C20.7922 38.2105 20.6886 38.1911 20.5984 38.1423Z" fill="#0895D1"/>
      <path d="M41.0378 11.5L34.1489 15.4286C34.2179 15.515 34.2581 15.6206 34.2638 15.7306V20.7778C34.2638 20.8515 34.2342 20.9222 34.1815 20.9743C34.1287 21.0264 34.0572 21.0557 33.9826 21.0557H28.1721C28.0973 21.0557 28.0255 21.0265 27.9724 20.9744C27.9192 20.9224 27.8891 20.8517 27.8884 20.7778V19.3281C27.8851 19.2313 27.8537 19.1374 27.7979 19.0575C27.759 18.998 27.7072 18.9477 27.6463 18.9102L21.1022 15.1411C21.0264 15.1031 20.9426 15.0833 20.8576 15.0833C20.7727 15.0833 20.6889 15.1031 20.6131 15.1411L14.1032 18.9078C14.0425 18.9447 13.9914 18.9952 13.954 19.0551C13.8977 19.1348 13.8655 19.2286 13.8611 19.3257V26.748C13.8658 26.8317 13.8903 26.9132 13.9328 26.9858C13.9753 27.0584 14.0345 27.1202 14.1056 27.166L20.6131 30.865C20.6934 30.9073 20.7838 30.9274 20.8748 30.923C20.9535 30.9225 21.0308 30.9026 21.0997 30.865L27.6439 27.1636C27.7152 27.1186 27.7747 27.0574 27.8172 26.9851C27.8597 26.9128 27.8842 26.8315 27.8884 26.748V25.3756C27.8891 25.3017 27.9192 25.231 27.9724 25.179C28.0255 25.127 28.0973 25.0978 28.1721 25.0978H33.9826C34.0195 25.0978 34.0561 25.105 34.0902 25.1189C34.1243 25.1329 34.1553 25.1534 34.1815 25.1792C34.2076 25.205 34.2283 25.2356 34.2424 25.2693C34.2566 25.303 34.2638 25.3391 34.2638 25.3756V30.4108C34.2592 30.4945 34.2346 30.576 34.1921 30.6486C34.1496 30.7212 34.0904 30.7829 34.0193 30.8287L21.0875 38.1447C21.023 38.1798 20.9508 38.1988 20.8772 38.2002V46.0018C21.7285 46.0203 22.5703 45.8231 23.3227 45.4292L39.326 36.3012C40.6661 35.5353 41.7715 33.6604 41.7715 32.131V13.8726C41.7362 13.0328 41.4836 12.2159 41.0378 11.5Z" fill="#043566"/>
      <path d="M54.3683 9.97293C54.8458 9.51084 55.4132 9.14919 56.0361 8.90984C56.715 8.65312 57.4368 8.52526 58.1637 8.53295C58.8976 8.52853 59.6244 8.67413 60.2986 8.9606C60.9871 9.26483 61.6067 9.70276 62.1205 10.2484L62.1694 10.2991L64.0744 8.53053L64.0304 8.47738C63.3282 7.65552 62.4356 7.01319 61.4284 6.60491C59.3088 5.7902 56.9613 5.767 54.8256 6.53967C53.8684 6.90561 52.9934 7.45399 52.2505 8.1536C51.5202 8.8455 50.938 9.67534 50.5386 10.5939C49.7309 12.5683 49.7309 14.7756 50.5386 16.7501C50.9383 17.6734 51.5218 18.508 52.2545 19.204C52.9872 19.9001 53.8541 20.4435 54.8036 20.8018C55.8318 21.1883 56.9242 21.3809 58.0243 21.3697C59.1977 21.3852 60.3621 21.1666 61.448 20.727C62.4549 20.3125 63.347 19.6664 64.05 18.8424L64.094 18.7917L62.189 17.0207L62.1376 17.0738C61.63 17.6301 61.0103 18.0753 60.3182 18.3809C59.6444 18.6686 58.9172 18.8142 58.1832 18.8086C57.4565 18.817 56.7347 18.69 56.0557 18.4341C55.4324 18.1937 54.8649 17.8311 54.3875 17.3681C53.91 16.9051 53.5324 16.3513 53.2776 15.7401C52.743 14.4111 52.743 12.9304 53.2776 11.6014C53.529 10.9929 53.8997 10.4395 54.3683 9.97293Z" fill="black"/>
      <path d="M74.6095 10.3149C73.6268 9.79702 72.5268 9.53508 71.4132 9.55383C70.305 9.53357 69.2101 9.79568 68.2341 10.3149C66.8543 11.1101 65.8486 12.4121 65.4363 13.9368C65.024 15.4616 65.2385 17.0856 66.0332 18.4547C66.5633 19.3342 67.3236 20.0562 68.2341 20.5446C69.2028 21.0599 70.2887 21.3219 71.3888 21.3057C72.4965 21.3209 73.5901 21.0591 74.5679 20.5446C75.4793 20.0618 76.2403 19.3426 76.7689 18.4644C77.2896 17.5339 77.5627 16.4881 77.5627 15.4249C77.5627 14.3618 77.2896 13.3159 76.7689 12.3855C76.2482 11.5179 75.5028 10.8032 74.6095 10.3149ZM73.1422 18.491C72.619 18.7781 72.0288 18.9247 71.4304 18.9162C70.8422 18.9229 70.2627 18.7762 69.7503 18.491C69.2454 18.2071 68.8318 17.7884 68.5569 17.2829C68.2553 16.7158 68.1038 16.0824 68.1167 15.4418C68.1007 14.8017 68.2523 14.1684 68.5569 13.6032C68.8345 13.0996 69.2475 12.6815 69.7503 12.3952C70.2727 12.116 70.8573 11.9699 71.4512 11.9699C72.0451 11.9699 72.6296 12.116 73.152 12.3952C73.6531 12.6806 74.0631 13.0991 74.3356 13.6032C74.6325 14.1709 74.7805 14.803 74.766 15.4418C74.7781 16.0815 74.6294 16.7141 74.3332 17.2829C74.0603 17.7915 73.6441 18.2111 73.1349 18.491H73.1422Z" fill="black"/>
      <path d="M88.7568 10.0927C87.9815 9.72199 87.1285 9.53739 86.2673 9.55393C85.3344 9.53498 84.4109 9.7415 83.5772 10.1555C83.1385 10.3788 82.7428 10.6765 82.4083 11.035V9.70373H79.7183V21.1488H82.5306V15.3501C82.5071 14.7074 82.6405 14.0685 82.9194 13.4873C83.1596 13.0172 83.5415 12.6322 84.0125 12.3856C84.5193 12.1301 85.0822 12.0023 85.651 12.0135C86.4972 12.0135 87.1575 12.2551 87.6074 12.7383C88.0574 13.2215 88.297 13.9464 88.297 14.9346V21.1488H91.1216V14.5939C91.1596 13.605 90.9389 12.623 90.4809 11.7429C90.0858 11.0352 89.4853 10.4605 88.7568 10.0927Z" fill="black"/>
      <path d="M100.925 18.5288C100.523 18.8379 100.024 18.997 99.5144 18.9782C99.3037 18.9907 99.0928 18.9598 98.8949 18.8875C98.697 18.8151 98.5166 18.7028 98.365 18.5578C98.0747 18.2231 97.9282 17.7897 97.9566 17.3498V12.0344H101.045V9.79224H97.9566V7.20703H95.1516V9.79224H93.2661V12.0537H95.1516V17.4464C95.1516 18.7052 95.5062 19.6741 96.2032 20.3264C96.9001 20.9787 97.8979 21.3098 99.1695 21.3098C99.6474 21.3095 100.123 21.2444 100.583 21.1165C101.02 21.0014 101.429 20.8027 101.789 20.5318L101.83 20.498L101.001 18.4733L100.925 18.5288Z" fill="black"/>
      <path d="M108.208 10.167C107.738 10.4165 107.328 10.7645 107.007 11.1866V9.70313H104.295V21.1482H107.108V15.6105C107.108 14.4507 107.404 13.5785 107.986 13.0156C108.568 12.4526 109.375 12.1675 110.38 12.1675C110.463 12.1675 110.551 12.1675 110.651 12.1675C110.752 12.1675 110.849 12.1869 110.947 12.1989H111.03V9.55092H110.957C110.003 9.52521 109.058 9.73709 108.208 10.167Z" fill="black"/>
      <path d="M121.639 10.7589C120.729 9.95913 119.416 9.55081 117.726 9.55081C116.827 9.54851 115.932 9.67133 115.068 9.91565C114.27 10.1423 113.52 10.5098 112.854 11.0005L112.806 11.0367L113.928 13.0952L113.996 13.0421C114.457 12.6731 114.981 12.3906 115.544 12.2085C116.139 12.0165 116.76 11.9179 117.386 11.9162C118.335 11.9162 119.051 12.1264 119.514 12.5419C119.75 12.7689 119.935 13.0433 120.054 13.3468C120.174 13.6503 120.227 13.9756 120.208 14.3009V14.3975H117.303C116.344 14.364 115.388 14.5167 114.488 14.8469C113.853 15.086 113.311 15.5195 112.942 16.0839C112.626 16.6149 112.462 17.2217 112.471 17.838C112.462 18.4741 112.65 19.0975 113.009 19.6259C113.389 20.169 113.916 20.5959 114.53 20.8581C115.25 21.164 116.028 21.3139 116.811 21.2979C117.701 21.3316 118.584 21.1386 119.377 20.7373C119.748 20.5348 120.075 20.2613 120.338 19.9328V21.1408H123.015V14.4555C123.015 12.8053 122.553 11.561 121.639 10.7589ZM120.21 17.5239C119.994 18.0567 119.603 18.5024 119.1 18.79C118.572 19.0831 117.975 19.2331 117.369 19.2249C116.816 19.2569 116.267 19.1129 115.804 18.8141C115.63 18.6962 115.488 18.5372 115.392 18.3516C115.296 18.1659 115.248 17.9595 115.253 17.751C115.249 17.5553 115.288 17.361 115.368 17.1816C115.447 17.0022 115.565 16.842 115.713 16.7121C116.019 16.4367 116.621 16.2966 117.496 16.2966H120.21V17.5239Z" fill="black"/>
      <path d="M134.402 17.3615C134.103 17.8496 133.682 18.2532 133.179 18.5333C132.698 18.7866 132.161 18.9177 131.616 18.915C131.007 18.9236 130.406 18.7771 129.87 18.4898C129.354 18.2116 128.929 17.7921 128.647 17.2818C128.342 16.7124 128.19 16.0752 128.205 15.431C128.189 14.7907 128.342 14.1573 128.647 13.5924C128.931 13.0833 129.355 12.6643 129.87 12.3843C130.406 12.097 131.007 11.9506 131.616 11.9591C132.161 11.9572 132.698 12.0893 133.179 12.3433C133.679 12.617 134.1 13.013 134.402 13.4933L134.441 13.5513L136.595 12.2974L136.566 12.2369C136.147 11.3799 135.462 10.6779 134.61 10.234C133.692 9.76638 132.671 9.53064 131.638 9.54784C130.511 9.52888 129.398 9.79062 128.4 10.3089C127.484 10.7892 126.715 11.5044 126.175 12.3795C125.648 13.3078 125.371 14.3544 125.371 15.419C125.371 16.4835 125.648 17.5301 126.175 18.4584C126.712 19.3384 127.481 20.0575 128.4 20.5386C129.398 21.0569 130.511 21.3187 131.638 21.2997C132.673 21.317 133.695 21.0777 134.612 20.6039C135.459 20.1588 136.143 19.4625 136.568 18.613L136.6 18.5526L134.443 17.2963L134.402 17.3615Z" fill="black"/>
      <path d="M145.455 18.5288C145.052 18.8383 144.552 18.9974 144.042 18.9782C143.832 18.9909 143.621 18.96 143.424 18.8876C143.226 18.8152 143.046 18.7029 142.895 18.5578C142.605 18.2231 142.458 17.7897 142.487 17.3498V12.0344H145.575V9.79224H142.487V7.20703H139.682V9.79224H137.799V12.0537H139.684V17.4464C139.684 18.7052 140.039 19.6741 140.736 20.3264C141.433 20.9787 142.43 21.3098 143.702 21.3098C144.18 21.3095 144.655 21.2444 145.115 21.1165C145.552 21.0014 145.962 20.8027 146.321 20.5318L146.363 20.498L145.534 18.4733L145.455 18.5288Z" fill="black"/>
      <path d="M157.284 16.1535C157.026 15.7532 156.666 15.4267 156.24 15.2064C155.796 14.9794 155.325 14.8066 154.839 14.6918C154.35 14.571 153.829 14.472 153.322 14.3946C152.816 14.3173 152.344 14.2328 151.946 14.153C151.599 14.0854 151.267 13.9561 150.967 13.7713C150.851 13.6959 150.757 13.5914 150.694 13.4683C150.632 13.3452 150.604 13.2079 150.613 13.0706C150.62 12.8931 150.676 12.7209 150.776 12.573C150.876 12.4251 151.015 12.3072 151.178 12.2322C151.564 12.0051 152.156 11.8915 152.941 11.8915C153.491 11.8935 154.04 11.9535 154.577 12.0704C155.143 12.1973 155.686 12.4126 156.184 12.7082L156.25 12.7469L157.367 10.6062L157.309 10.5724C156.684 10.2236 156.007 9.97412 155.303 9.83306C154.535 9.65391 153.75 9.5591 152.961 9.55037C152.043 9.53159 151.13 9.68491 150.271 10.0022C149.574 10.2575 148.96 10.6931 148.493 11.2634C148.069 11.8061 147.845 12.4749 147.857 13.16C147.829 13.729 147.966 14.2941 148.253 14.7885C148.513 15.189 148.868 15.5207 149.287 15.7549C149.727 15.9976 150.199 16.1781 150.689 16.2913C151.195 16.4121 151.711 16.5087 152.217 16.5788C152.723 16.6488 153.195 16.7286 153.596 16.8204C153.941 16.8829 154.272 17.0047 154.575 17.1804C154.687 17.2477 154.779 17.3435 154.841 17.4578C154.902 17.5721 154.932 17.7008 154.927 17.8303C154.927 18.0055 154.877 18.1773 154.783 18.3256C154.688 18.474 154.554 18.593 154.394 18.6687C154.029 18.8789 153.433 18.9852 152.621 18.9852C151.894 18.9845 151.171 18.8828 150.474 18.6832C149.831 18.5078 149.216 18.2426 148.649 17.8956L148.581 17.8521L147.488 19.9927L147.541 20.029C148.197 20.4382 148.912 20.7454 149.662 20.9398C150.568 21.1907 151.506 21.3159 152.447 21.3119C153.394 21.3329 154.337 21.1848 155.23 20.8746C155.932 20.6288 156.556 20.2047 157.04 19.6448C157.474 19.1062 157.703 18.4342 157.688 17.7458C157.711 17.1877 157.571 16.6348 157.284 16.1535Z" fill="black"/>
      <path d="M53.3973 28.2854C53.9675 27.7314 54.6455 27.2974 55.3903 27.0097C56.1802 26.7044 57.0219 26.551 57.8701 26.5579C58.68 26.5522 59.4843 26.6905 60.2446 26.9662C61.0059 27.257 61.6924 27.7108 62.2548 28.2951L62.3062 28.3483L63.4164 27.2513L63.37 27.2006C62.6981 26.4698 61.8599 25.9075 60.9245 25.5601C59.9354 25.1939 58.8871 25.0089 57.8309 25.014C56.7519 25.0042 55.6807 25.196 54.6738 25.5794C53.7347 25.9407 52.8777 26.4827 52.1525 27.174C51.4369 27.8596 50.8695 28.6816 50.4847 29.5901C49.689 31.5376 49.689 33.7142 50.4847 35.6617C50.8674 36.5731 51.4331 37.3984 52.1476 38.0877C52.8622 38.7771 53.7107 39.3162 54.642 39.6725C56.664 40.4181 58.8903 40.4181 60.9123 39.6725C61.8473 39.3198 62.6851 38.7545 63.3578 38.0223L63.4042 37.9715L62.294 36.8746L62.2426 36.9278C61.6779 37.5118 60.9922 37.9683 60.2324 38.2663C59.4737 38.549 58.6687 38.6907 57.8578 38.6843C57.0099 38.691 56.1684 38.5385 55.3781 38.2349C54.6336 37.9454 53.9558 37.5107 53.385 36.9568C52.8205 36.404 52.3711 35.7474 52.062 35.0239C51.4268 33.4743 51.4268 31.7413 52.062 30.1917C52.3775 29.4766 52.831 28.8291 53.3973 28.2854Z" fill="#677A89"/>
      <path d="M74.0005 29.4845C73.0796 29.0022 72.0532 28.75 71.0109 28.75C69.9686 28.75 68.9422 29.0022 68.0213 29.4845C67.1621 29.9613 66.4491 30.6583 65.9573 31.5019C65.4581 32.414 65.1968 33.4345 65.1968 34.4713C65.1968 35.5081 65.4581 36.5287 65.9573 37.4407C66.4465 38.2944 67.1595 39.0022 68.0213 39.4896C68.9285 39.9928 69.9547 40.2488 70.995 40.2313C72.0413 40.2471 73.0734 39.9913 73.9883 39.4896C74.8563 39.0105 75.5745 38.3051 76.0645 37.4504C76.5732 36.5425 76.8312 35.5185 76.8128 34.481C76.8343 33.4432 76.576 32.4183 76.0645 31.5116C75.572 30.666 74.8593 29.9661 74.0005 29.4845ZM70.995 38.743C70.2606 38.7559 69.5365 38.5722 68.8992 38.2114C68.2824 37.8519 67.7754 37.3344 67.4319 36.7135C67.072 36.0178 66.8842 35.2477 66.8842 34.4665C66.8842 33.6852 67.072 32.9151 67.4319 32.2195C67.7767 31.6028 68.2837 31.0894 68.8992 30.7336C69.5455 30.3856 70.2699 30.2032 71.006 30.2032C71.7421 30.2032 72.4665 30.3856 73.1128 30.7336C73.731 31.0861 74.2387 31.6003 74.5801 32.2195C74.9338 32.917 75.118 33.6864 75.118 34.4665C75.118 35.2466 74.9338 36.016 74.5801 36.7135C74.2412 37.3377 73.7332 37.8564 73.1128 38.2114C72.4671 38.5712 71.7362 38.7547 70.995 38.743Z" fill="#677A89"/>
      <path d="M87.935 29.2707C87.173 28.9076 86.3352 28.7272 85.4895 28.744C84.495 28.7187 83.5122 28.96 82.6453 29.4422C82.0998 29.7539 81.6318 30.1824 81.2759 30.6962V28.8503H79.6716V40.1141H81.3395V34.1947C81.3183 33.4551 81.4812 32.7218 81.8139 32.0589C82.1145 31.4867 82.5823 31.017 83.1565 30.7107C83.7911 30.3883 84.4973 30.228 85.2107 30.2444C86.2573 30.2444 87.079 30.5392 87.6562 31.1214C88.2333 31.7037 88.5243 32.5711 88.5243 33.7308V40.1141H90.1946V33.5907C90.2268 32.6542 90.0208 31.7246 89.5954 30.8871C89.2232 30.1926 88.6434 29.6282 87.935 29.2707Z" fill="#677A89"/>
      <path d="M102.427 29.2707C101.665 28.9076 100.827 28.7272 99.9814 28.744C98.9869 28.7187 98.0041 28.96 97.1373 29.4422C96.5917 29.7539 96.1238 30.1824 95.7678 30.6962V28.8503H94.1636V40.1141H95.8314V34.1947C95.8102 33.4551 95.9732 32.7218 96.3058 32.0589C96.6064 31.4867 97.0742 31.017 97.6484 30.7107C98.283 30.3883 98.9892 30.228 99.7026 30.2444C100.749 30.2444 101.571 30.5392 102.148 31.1214C102.725 31.7037 103.016 32.5711 103.016 33.7308V40.1141H104.687V33.5907C104.719 32.6542 104.513 31.7246 104.087 30.8871C103.715 30.1926 103.135 29.6282 102.427 29.2707Z" fill="#677A89"/>
      <path d="M115.958 29.4641C115.082 28.9885 114.098 28.7408 113.099 28.7442C112.1 28.7476 111.118 29.0019 110.245 29.4834C109.407 29.9702 108.716 30.6713 108.247 31.5129C107.748 32.4195 107.495 33.4384 107.514 34.4702C107.496 35.5099 107.758 36.5356 108.274 37.442C108.774 38.2991 109.504 39.0038 110.382 39.4788C111.341 39.9876 112.417 40.2431 113.505 40.2205C114.364 40.2264 115.216 40.0621 116.009 39.7373C116.747 39.4382 117.4 38.9664 117.912 38.3625L117.951 38.3142L116.997 37.2318L116.943 37.2946C116.536 37.7695 116.02 38.1408 115.437 38.377C114.836 38.6161 114.194 38.7368 113.547 38.7322C112.75 38.7462 111.962 38.5634 111.255 38.2006C110.606 37.862 110.066 37.3495 109.697 36.722C109.392 36.194 109.209 35.6056 109.162 34.9993H118.616V34.9269C118.616 34.8423 118.616 34.765 118.616 34.6853C118.616 34.6055 118.616 34.5282 118.616 34.4557C118.637 33.4157 118.39 32.3875 117.9 31.467C117.451 30.6361 116.779 29.9432 115.958 29.4641ZM109.651 32.1532C109.973 31.5485 110.461 31.0459 111.06 30.7035C111.681 30.3519 112.386 30.1717 113.102 30.1817C113.817 30.169 114.523 30.3486 115.144 30.7011C115.734 31.0509 116.217 31.5518 116.542 32.1508C116.813 32.6459 116.979 33.19 117.031 33.7502H109.186C109.234 33.1931 109.392 32.6505 109.651 32.1532Z" fill="#677A89"/>
      <path d="M129.777 37.1409C129.425 37.6602 128.939 38.0772 128.369 38.3489C127.796 38.6153 127.17 38.7508 126.537 38.7452C125.792 38.7553 125.058 38.572 124.407 38.2136C123.78 37.863 123.263 37.3479 122.913 36.7253C122.553 36.028 122.365 35.2563 122.365 34.4735C122.365 33.6907 122.553 32.919 122.913 32.2217C123.263 31.6 123.78 31.0859 124.407 30.7358C125.012 30.4115 125.684 30.2305 126.372 30.207C127.059 30.1835 127.743 30.3182 128.369 30.6005C128.942 30.8753 129.429 31.2986 129.777 31.8255L129.819 31.8859L131.071 31.0427L131.037 30.9847C130.604 30.2502 129.959 29.6609 129.183 29.2934C128.361 28.9138 127.464 28.723 126.557 28.7353C125.502 28.717 124.46 28.9666 123.532 29.4601C122.659 29.9296 121.934 30.6276 121.436 31.4776C120.937 32.3918 120.675 33.4142 120.675 34.453C120.675 35.4917 120.937 36.5142 121.436 37.4284C121.933 38.2834 122.658 38.9877 123.532 39.4652C124.458 39.968 125.5 40.2236 126.557 40.2069C127.465 40.2202 128.364 40.0251 129.183 39.6367C129.951 39.2697 130.595 38.6906 131.037 37.9696L131.073 37.9092L129.819 37.0684L129.777 37.1409Z" fill="#677A89"/>
      <path d="M139.642 38.23C139.189 38.6068 138.611 38.8039 138.019 38.7833C137.763 38.8001 137.507 38.7636 137.267 38.6762C137.027 38.5887 136.808 38.4524 136.625 38.276C136.279 37.873 136.104 37.3537 136.136 36.8263V30.2497H139.559V28.8556H136.136L136.238 26.3984H134.57L134.465 28.8556H132.45V30.2497H134.465V36.9133C134.465 37.9594 134.761 38.7809 135.346 39.3559C135.93 39.931 136.766 40.2257 137.84 40.2257C138.287 40.2263 138.731 40.1603 139.158 40.03C139.569 39.9121 139.949 39.7049 140.268 39.4236L140.31 39.3849L139.708 38.1769L139.642 38.23Z" fill="#677A89"/>
      <path d="M149.816 29.4641C148.94 28.9885 147.956 28.7408 146.957 28.7442C145.958 28.7476 144.976 29.0019 144.103 29.4834C143.264 29.9708 142.574 30.6717 142.103 31.5129C141.605 32.4199 141.352 33.4385 141.369 34.4702C141.351 35.5099 141.614 36.5356 142.13 37.442C142.63 38.2991 143.359 39.0038 144.238 39.4788C145.196 39.9873 146.271 40.2428 147.358 40.2205C148.217 40.2269 149.069 40.0625 149.862 39.7373C150.601 39.4389 151.255 38.967 151.767 38.3625L151.806 38.3142L150.853 37.2318L150.799 37.2946C150.391 37.769 149.875 38.1402 149.292 38.377C148.692 38.6159 148.05 38.7365 147.402 38.7322C146.605 38.7461 145.818 38.5634 145.111 38.2006C144.461 37.862 143.921 37.3495 143.553 36.722C143.247 36.1942 143.063 35.6058 143.015 34.9993H152.472V34.9269C152.472 34.8423 152.472 34.765 152.484 34.6853C152.496 34.6055 152.484 34.5282 152.484 34.4557C152.504 33.4157 152.258 32.3875 151.767 31.467C151.315 30.6348 150.64 29.9418 149.816 29.4641ZM143.509 32.1532C143.829 31.5476 144.318 31.0448 144.917 30.7035C145.538 30.3519 146.244 30.1717 146.959 30.1816C147.675 30.169 148.381 30.3486 149.001 30.7011C149.591 31.0515 150.074 31.5523 150.4 32.1508C150.672 32.6455 150.838 33.1897 150.889 33.7502H143.032C143.084 33.1922 143.246 32.6497 143.509 32.1532Z" fill="#677A89"/>
      <path d="M164.332 24.2529L164.229 30.7763C163.826 30.2143 163.304 29.7459 162.699 29.404C161.877 28.9524 160.949 28.7225 160.008 28.7372C158.989 28.7243 157.983 28.9741 157.091 29.462C156.239 29.9336 155.536 30.6284 155.059 31.4698C154.574 32.3958 154.321 33.4232 154.321 34.4657C154.321 35.5082 154.574 36.5356 155.059 37.4617C155.534 38.3096 156.236 39.0119 157.089 39.4912C157.981 39.9816 158.988 40.2316 160.008 40.216C160.956 40.2309 161.89 39.9974 162.716 39.5395C163.345 39.1759 163.882 38.6761 164.288 38.0778V40.1097H165.892L165.995 24.248L164.332 24.2529ZM160.121 38.7494C159.39 38.759 158.67 38.5755 158.035 38.2179C157.416 37.8608 156.909 37.3426 156.568 36.7199C156.208 36.0243 156.02 35.2542 156.02 34.473C156.02 33.6917 156.208 32.9216 156.568 32.226C156.912 31.6085 157.419 31.0949 158.035 30.7401C158.67 30.3825 159.39 30.199 160.121 30.2086C160.862 30.1975 161.593 30.3809 162.239 30.7401C162.858 31.0918 163.366 31.6061 163.706 32.226C164.058 32.9239 164.242 33.6931 164.242 34.473C164.242 35.2528 164.058 36.022 163.706 36.7199C163.367 37.3442 162.859 37.863 162.239 38.2179C161.592 38.5745 160.861 38.7554 160.121 38.7422V38.7494Z" fill="#677A89"/>
    </g>
    <defs>
      <clipPath id="clip0_577_6679">
        <rect width="166" height="46" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>

<style scoped lang="scss">

</style>
