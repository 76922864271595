<template>
    <div class="maintenance-mode text-center pt-5">
        <AppLogo/>
        <h2 class="mt-5">Maintenance in progress!</h2>
        <h5 class="mt-3">
            We're currently working to make our app even better. <br>
            Expect the system to be back online 10pm PST Monday 11/11/2024.
        </h5>
    </div>
</template>

<script>
import AppLogo from "@/components/AppLogo.vue";
import config from "@/domain/config";

export default {
    components: {AppLogo},
    created() {
        this.checkMaintenanceMode();
    },
    methods: {
        checkMaintenanceMode() {
            if (!config.app.maintenanceMode) {
                this.$router.push({name: 'Login'});
            }
        }
    }
}
</script>

<style scoped lang="scss">
.maintenance-mode {
    width: 100vw;
    height: 100vh;
    background: #F2F5F8;
}
</style>
